.event-card {
  .event-card-image {
    overflow: hidden;

    border: 1px solid #f0f0f0;
    border-bottom: 0px;

    img {
      object-fit: cover;
      width: 100%;
      height: 210px;
    }
  }

  .ant-card-meta-description {
    min-height: 100px;

    div {
      margin-bottom: 10px;
    }
  }

  .ant-card-meta-title {
    min-height: 50px;
    overflow: auto;
    white-space: pre-line;
  }

  .ant-card-body {
    min-height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
}
