.home-banner {
  max-height: 400px;
  position: relative;
  font-family: 'Encode Sans' !important;
  // background: linear-gradient(#015f80, #9a5982);
  background-image: linear-gradient(
    to right,
    #e72477,
    #c43d99,
    #9551ab,
    #605dad,
    #2c619f,
    #11679c,
    #006d98,
    #007191,
    #00809f,
    #008fab,
    #009eb8,
    #00aec3
  );
  // background-image: linear-gradient(to bottom, #e72477, #c43d99, #9551ab, #605dad, #2c619f, #11679c, #006d98, #007191, #00809f, #008fab, #009eb8, #00aec3);
}

.home-banner-opacity-layer {
  height: 100%;
  width: 100%;
  opacity: 0.05;
  position: absolute;
  background-position: center;
  background-image: url('https://encadenar.com.ar/wp/wp-content/uploads/2018/10/StockSnap_QYL7NTI3LP.jpg');
}

.home-banner__content {
  padding: 50px 60px;

  text-align: center;

  h1 {
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.4rem;
    color: white;
    font-weight: 500;
  }

  p:not(:first-of-type) {
    margin-top: 50px;
  }
}
