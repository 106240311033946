.register-page-container {
  height: 100vh;
  background-repeat: repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;

  .register-page-logo-container {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 50px;

    img {
      max-width: 180px;
    }
  }

  .register-page-title-container {
    display: flex;
    justify-content: center;

    margin-top: -40px;
    // margin-bottom: -30px;

    h2 {
      opacity: 1;
      width: 100%;
      text-align: center;
      background: linear-gradient(45deg, #005e80, #a75682);
      // background: #015f80;
      padding: 10px;
      border-radius: 8px;
      color: white;
    }
  }

  .ant-card {
    height: 99vh;
  }

  @media (min-width: 760px) {
    .ant-card {
      height: auto;
      border-radius: 6px;
      background-size: 100%;
      background-position: top;
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.8);
      background-image: radial-gradient(
        // circle at 93% 1e2%,
        rgba(22, 119, 255, 0.17) 0%,
        rgba(255, 255, 255, 0.05) 23%,
        rgba(255, 255, 255, 0.03) 87%,
        rgba(22, 119, 255, 0.12) 109%
      );
      padding: 32px;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
