.login-page-container {
  height: 100vh;
  background-color: 'white';
}

.login-page-subtitle {
  margin-bottom: 35px;
  padding-bottom: 5px;
  font-size: 25px;
  font-family: DIN;
  // font-weight: 600;
  text-align: center;
  line-height: 25px;
}

.ant-pro-form-login-page-top {
  width: 328px;
  margin: 0 auto;
}

.login-page-footer-logo {
  max-height: 70px;
  margin-top: 20px;
  object-fit: contain;
}

.login-page-footer {
  display: flex;
  flex-direction: column;
}

@media (min-width: 800px) {
  .login-page-footer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 540px);
    justify-content: space-between;
  }
}

@media (max-width: 800px) {
  .ant-pro-form-login-page-notice-activity,
  .ant-pro-form-login-page-container {
    max-width: none !important;
  }
}

.login-page-background-overlay {
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  position: absolute;

  // background-image: linear-gradient(to right, #e72477, #b545a1, #715aae, #2c619f, #085e7e);

  background-image: linear-gradient(to right bottom, #e72477, #b545a1, #715aae, #2c619f, #085e7e);

  // background-image: linear-gradient(
  //   to right,
  //   #e72477,
  //   #c43d99,
  //   #9551ab,
  //   #605dad,
  //   #2c619f,
  //   #11679c,
  //   #006d98,
  //   #007191,
  //   #00809f,
  //   #008fab,
  //   #009eb8,
  //   #00aec3
  // );
}
