@import './_variables';
@import './_mixins';

* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  height: 100%;
  // overflow-x: hidden;
}

@font-face {
  font-weight: 800;
  font-family: 'DIN';
  font-style: normal;
  src: local('din'), url('../assets/fonts/dinm.woff') format('woff');
}

@font-face {
  font-weight: 300;
  font-family: 'DINR';
  font-style: normal;
  src: local('din'), url('../assets/fonts/dinRegular.woff') format('woff');
}

@font-face {
  font-family: 'Encode Sans';
  src: url('../assets/fonts/encodeSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
.ant-avatar,
.ant-steps,
button span,
.welcome-modal,
.ant-btn span,
.ant-list-item,
.ant-drawer-title,
.ant-alert-message,
.ant-pro-card-title,
.ant-card-head-title,
.ant-statistic-title,
// .ant-descriptions-item-label,
.ant-tabs-tab .ant-tabs-tab-btn,
.ant-typography,
.ant-form-item-label label,
.ant-modal-confirm-body,
.ant-pro-table-list-toolbar-title,
.ant-table-thead .ant-table-cell,
.ant-form-item-control-input-content {
  font-family: 'Encode Sans' !important;
}

body,
.ant-button,
.ant-result,
.ant-btn {
  font-family: 'Encode Sans', sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
.ant-list-item-meta-description,
.ant-list-item,
// .ant-table-row,
// .ant-table-cell,
// .ant-table-cell *,
.ant-select-selection-placeholder {
  font-family: 'Encode Sans', sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-text-color;
  background-color: $main-background-color;
  font-size: $main-text-size;
}

a {
  color: #00aec3;
}

a:active {
  color: #0095a7;
}

@media (max-width: $phone-size) {
  .ant-pro-page-container-children-content {
    padding: 5px !important;
  }
}

.ant-layout .ant-layout-content {
  flex: auto;
  margin-bottom: 20px;
  min-height: fit-content !important;
}

.leaflet-container {
  min-height: 300px;
}

.ant-page-header.ant-pro-page-container-warp-page-header {
  background: white;
  margin-bottom: 15px;
  border-bottom: 1px solid whitesmoke;
}

.page-tabs-card .ant-tabs-nav::before {
  border: 0px;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.ant-pro-table-column-setting-overlay {
  min-width: 300px;
}

.ant-pro-table-column-setting-overlay .ant-popover-inner-content {
  min-width: 265px;
}

.ant-alert-info {
  background-color: #06aec3d9;
  border: 1px solid #48bacc;

  & .ant-alert-message,
  & .anticon-info-circle {
    color: white;
  }
}

.ant-alert-info.light {
  background-color: white;
  border: 1px solid #00aec3;

  & .ant-alert-message,
  & .anticon-info-circle {
    color: #00aec3;
  }
}

// .ant-form-item-explain-success {
//   font-size: 12px;
//   margin-top: 5px;
// }

.ant-list-item-meta-title {
  margin-top: 0px !important;
}

.ant-table-wrapper .ant-table-row-expand-icon:active {
  color: #06aec3 !important;
}

.ant-table-wrapper .ant-table-row-expand-icon:focus,
.ant-table-wrapper .ant-table-row-expand-icon:hover {
  color: #01a2b6 !important;
}

.custom-tabs-selector > .ant-tabs-nav {
  &::before {
    border-bottom: none !important;
  }
  background: white;
  padding: 0px 20px;
  border-radius: 7px;
  border: 1px solid whitesmoke;
  margin-bottom: 20px;
}

.ant-pro-form-login-page {
  background-size: cover;
  background-position-y: top;
  background-position-x: right;
}

.ant-pro-steps-form-step-active {
  max-width: 88vw !important;
}

.ant-page-header-heading-title {
  white-space: break-spaces !important;
}

input::placeholder,
.ant-input-disabled.ant-input[disabled],
.ant-select-selection-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5d5d5d !important;
  opacity: 1; /* Firefox */
}

@media (min-width: 700px) {
  .ant-pro-table-list-toolbar-left {
    max-width: calc(100% - 350px) !important;
  }

  .ant-pro-steps-form {
    .ant-row-stretch > .ant-col {
      flex: 1;
    }
  }
}

.balance-month-picker {
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn,
  .ant-picker-year-btn {
    display: none;
  }
}

.ant-tabs-top.ant-pro-page-container-tabs {
  margin-bottom: -28px;

  .ant-tabs-nav-wrap {
    margin-bottom: -4px;
  }
  & .ant-tabs-nav::before {
    border-bottom: none !important;
  }
}

.ant-layout-sider-dark .ant-menu-title-content {
  color: white;
}

.ant-pro-form-list-container {
  width: 100%;
}

.ql-editor {
  min-height: 250px;
}

.ql-toolbar.ql-snow {
  border-radius: 5px 5px 0px 0px;
}

.ql-container.ql-snow {
  border-radius: 0px 0px 5px 5px;
}

.ant-form-item-control-input .ant-form-item-control-input-content .ant-space-compact {
  width: 100% !important;
}

.ant-pro-table-column-setting-list-item-title {
  max-width: 180px !important;
}

button {
  box-shadow: none !important;
}

.ant-pro-steps-form-step-active {
  max-width: 88vw !important;
}

@media (min-width: 700px) {
  .ant-pro-table-list-toolbar-left {
    max-width: calc(100% - 350px) !important;
  }

  .ant-pro-steps-form {
    .ant-row-stretch > .ant-col {
      flex: 1;
    }
  }
}

.ant-form-item-control-input-content {
  font-weight: 100 !important;
  font-family: 'Encode Sans' !important;
  font-size: 14px;
  line-height: 1.5714285714285714;
  font-family: 'Encode Sans', sans-serif !important;
  text-rendering: optimizeLegibility;
}

.quill-text {
  p {
    margin: 0 !important;
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.inscription-form {
  .ant-select-selector {
    border-radius: 15px;
  }
}

.ant-drawer-body .ant-pro-sider-menu .ant-pro-base-menu-inline-item-text {
  color: black !important;
  font-weight: 200 !important;
  font-family: 'Encode Sans' !important;
}

iframe.ql-video {
  width: 100%;
  min-height: 400px;
  border-radius: 10px;
}

.white-layout {
  .ant-layout-content {
    background-color: white;
  }
}

.ant-table-cell {
  font-family: 'Encode Sans';
  font-weight: 100;

  a {
    color: #015f80;
  }
}

.ant-alert-info {
  .ant-alert-description {
    font-family: 'Encode Sans';
    color: white !important;
    // color: black;
  }

  background-color: #015f80;
  border: 1px solid #015f80;
  border-radius: 20px;

  // background-color: #f5f5f5;
  // border: 1px solid #f1f1f1;

  & .ant-alert-message,
  & .anticon-info-circle {
    color: white;
    // color: #015f80;
  }
}

.ant-image-mask {
  border-radius: 5px;
}

.ant-image-img {
  border: 1px solid #efefef;
}

@media (max-width: 700px) {
  .ant-pro-page-container-children-container {
    padding-inline: 10px;
  }

  .ant-transfer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.ant-transfer {
  .ant-transfer-list {
    border: 1px solid #f0f0f0;
  }

  .ant-table-row-selected > .ant-table-cell {
    background: #f0f0f0 !important;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.ant-form-item-control-input-content {
  word-wrap: break-word;
}

.ant-input-outlined:focus-within,
.ant-input-outlined:focus {
  box-shadow: none !important;
}

.auth-background {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
  background-position-x: 80% !important;
}

.ant-card-meta {
  cursor: default !important;
}

.event-card-image {
  cursor: default !important;

  overflow: hidden;
  text-align: center;
}

.ant-card-body {
  word-wrap: break-word !important;
}

// scheduler / calendar

.rct-header-root {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #015f80 !important;
}

.rct-item {
  border-radius: 8px;
}

.corte-calendar,
.reunion-calendar-pending,
.reunion-calendar-active,
.reunion-calendar-past {
  border-radius: 20px !important;
  overflow: hidden;
}

.corte-calendar {
  background-color: #5d5d5d !important;
  border: 1px solid #5d5d5d !important;
}

.reunion-calendar-pending {
  background-color: #2196f3 !important;
  border: 1px solid #006ec5 !important;
}

.reunion-calendar-active {
  background-color: #015f80 !important;
  border: 1px solid #015f80 !important;
}

.reunion-calendar-past {
  background-color: #004056 !important;
  border: 1px solid #004056 !important;
}

.react-calendar-timeline {
  font-family: 'Encode Sans', sans-serif !important;
}

.react-calendar-timeline .rct-dateHeader {
  font-size: 12px !important;
}

.rct-outer {
  border-end-end-radius: 5px;
  border-end-start-radius: 5px;
}

.react-calendar-timeline {
  border-radius: 5px;
  border: 1px solid #dadada;
}

.rct-calendar-header {
  border: 0px !important;
}

.list-break-item {
  background-color: #f0f0f04a;
}

.list-reunion-item,
.list-break-item {
  padding-left: 5px !important;
}

.ant-result-subtitle {
  font-size: 16px !important;
}

// .ant-modal-confirm-title {
//   font-size: 1.1rem !important;
// }

// .ant-modal-confirm-content {
//   font-size: 0.9rem !important;
// }

// .ant-modal-body .anticon {
//   margin-top: 3px !important;
// }

// .ant-modal-confirm-btns {
//   button span {
//     font-size: 0.91rem !important;
//   }
// }

@media (max-width: $tablet-size) {
  .ant-tabs-tabpane-active {
    .ant-pro-table {
      .ant-pro-card-body {
        padding-inline: 0px !important;
      }
    }
  }
}

.ant-list-item-meta-description span {
  word-break: break-word !important;
}

.mention-quill {
  border-radius: 4px;
  padding: 3px;
  -webkit-user-select: all;
  user-select: all;
}

.mention {
  padding: 0 !important;
  margin-right: 0 !important;
  background-color: transparent !important;
}

.mention > span {
  margin: 0px 0px !important;
}

.ant-table-row.ant-table-row-selected > .ant-table-cell {
  background: #fafafa !important;
}

.ql-disabled {
  .ql-editor {
    background-color: #f5f5f5 !important;
  }
}

.message-list-item {
  cursor: pointer;
  padding: 15px 20px !important;

  .ant-list-item-meta-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}

.email-viewer {
  .ant-list-item:hover {
    background-color: transparent !important;
  }
}

.ant-transfer-operation {
  align-self: baseline !important;
}

.ant-form-item-label label,
.ant-tabs-tab {
  font-weight: 700;
}

.login-form-container {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

.ant-btn-compact-item.ant-btn-compact-first-item {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  border-start-end-radius: unset !important;
  border-end-end-radius: unset !important;
}

.ant-btn-compact-item.ant-btn-compact-last-item {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-start-start-radius: unset !important;
  border-end-start-radius: unset !important;
}
